import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';

import style from '../styles/pages/page.scss';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

interface IPageProps {
  data: {
    ghostPage: {
      codeinjection_styles: any;
      title: string;
      html: string;
      feature_image?: string;
    };
  };
  location: any;
}

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
const Page = ({ data, location }: IPageProps) => {
  const page = data.ghostPage;

  return (
    <>
      <MetaData data={data} location={location} />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
      </Helmet>
      <Layout headerClassName={style.header}>
        <div className={style.page}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <article>
                  <h1>{page.title}</h1>

                  {/* The main page content */}
                  <section
                    className="load-external-scripts"
                    dangerouslySetInnerHTML={{ __html: page.html }}
                  />
                </article>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Page;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`;
